/* @flow */
/* Signup Form as displayed, for example, on /signup/buyer */
import { Component } from 'react';
import get from 'lodash/get';
import t from 'counterpart';

import { ThemeProvider } from '@eyeem-ui/theme';
import { Text, Box } from '@eyeem-ui/atoms';
import { SubmissionError } from 'redux-form';
import Form from '../signupForm/form.jsx';

import { track } from '../../../helpers/tools';
import {
  PRODUCTIONS_DEVELOPMENT_URL,
  PRODUCTIONS_STAGING_URL,
  PRODUCTIONS_PRODUCTION_URL,
} from '../../../constants/misc';
import withRouter from '../../../helpers/hoc/withRouter.jsx';

type Props = {
  enterpriseCustomerRequest: Function,
  enterpriseCustomerRegister: Function,
  showNotification: Function,
  isAuthPending: boolean,
  eventPosition: string,

  buyer: boolean,
  className: string,
  query: Object,
} & WithRouterProps;

type UserVerificationResponse = {
  invitedUser?: {
    email: string,
    name: string,
  },
  customer?: {
    name: string,
    contact: string,
    backgroundPhoto: string,
    customerLogo: string,
  },
};

type State = {
  signupToken: string,
  verificationResponse: UserVerificationResponse,
  isRegisterPending: boolean,
};

class EnterpriseUserSignupForm extends Component<Props, State> {
  state = {
    signupToken: '',
    verificationResponse: {},
    isRegisterPending: false,
  };

  submit = (values: {}) => {
    this.setState({ isRegisterPending: true });
    return this.props
      .enterpriseCustomerRegister({
        password: values.password,
        token: this.state.signupToken,
        name: values.name,
      })
      .then(() => {
        this.setState({ isRegisterPending: false });
        this.track('valid');
        const environment = this.props.query.referrer;
        switch (environment) {
          case 'production-development':
            return this.props.navigate(PRODUCTIONS_DEVELOPMENT_URL);

          case 'production-staging':
            return this.props.navigate(PRODUCTIONS_STAGING_URL);

          case 'production':
            return this.props.navigate(PRODUCTIONS_PRODUCTION_URL);

          default:
            return this.props.navigate('/');
        }
      })
      .catch((res) => {
        this.setState({ isRegisterPending: false });
        const errorFieldsWithMessages = get(
          res,
          'body.messagesByField',
          undefined
        );

        if (res.statusCode >= 400 && !!errorFieldsWithMessages) {
          throw new SubmissionError(errorFieldsWithMessages);
        }

        this.props.showNotification({
          notification: 'signupErrorUnknown',
          type: 'warning',
        });
      });
  };

  track = (suffix: 'valid' | 'invalid') =>
    track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.button'),
      eventName: t(`authForms.signup.submit.eventName.enterprise`, { suffix }),
      eventLabel: t('authForms.signup.submit.label'),
      eventPosition: this.props.eventPosition,
    });

  // eslint-disable-next-line
  async UNSAFE_componentWillMount() {
    const { token = '' } = this.props.query;

    if (token.length) {
      try {
        const { body } = await this.props.enterpriseCustomerRequest({
          token,
        });

        if (body && body.customer) {
          this.setState({
            signupToken: token,
            verificationResponse: body,
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error('enterpriseCustomerRequest ERROR: ', err);
      }
    }
  }

  render() {
    const customHeadline = get(
      this.state,
      'verificationResponse.customer.name'
    );

    const prefilledEmail = get(
      this.state,
      'verificationResponse.invitedUser.email'
    );

    const prefilledName = get(
      this.state,
      'verificationResponse.invitedUser.name'
    );

    return (
      <div className={this.props.className}>
        <ThemeProvider>
          <Box mb={1}>
            <Text as="h1" variant="title3">
              {customHeadline
                ? t('authForms.enterpriseBuyerSignup.headline', {
                    customerName: customHeadline,
                  })
                : t('authForms.enterpriseBuyerSignup.defaultHeadline')}
            </Text>
          </Box>
          <Box pb={3}>
            <Text color="grey50">
              {t('authForms.enterpriseBuyerSignup.signingUpAs')}
            </Text>
            <Text> {prefilledEmail}</Text>
          </Box>
        </ThemeProvider>

        <Box mt={2}>
          <Form
            isEnterpriseSignup
            buyer={this.props.buyer}
            onSubmit={this.submit}
            prefilledEmail={prefilledEmail}
            initialValues={{
              name: prefilledName,
            }}
            isAuthPending={this.state.isRegisterPending}
            withNewsletterSignup
          />
        </Box>
      </div>
    );
  }
}

export default withRouter(EnterpriseUserSignupForm);
