// @flow
import t from 'counterpart';
import { Helmet } from 'react-helmet-async';
import { truncateBeforeFirstWordThatExceeds } from '../../helpers/tools';
import { DESCRIPTION_MAX_LENGTH } from '../../constants/misc';

type AuthFormsMetaProps = {
  form: string,
  language: string,
};

function getMetas(form: string, language: string) {
  switch (form) {
    case 'creatorSignup':
      return {
        description: t('meta.description.signupCreator', { locale: language }),
        title: t('meta.title.signupCreator', { locale: language }),
      };
    case 'buyerSignup':
      return {
        description: t('meta.description.signupBuyer', { locale: language }),
        title: t('meta.title.signupBuyer', { locale: language }),
      };
    case 'login':
      return {
        description: t('meta.description.login', { locale: language }),
        title: t('meta.title.login', { locale: language }),
      };
    default:
      return {
        description: t('meta.description.signup', { locale: language }),
        title: t('meta.title.signup', { locale: language }),
      };
  }
}

function AuthFormsMeta({ form, language }: AuthFormsMetaProps) {
  let { description, title } = getMetas(form, language);
  description = truncateBeforeFirstWordThatExceeds(
    DESCRIPTION_MAX_LENGTH,
    description
  );

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

export default AuthFormsMeta;
