/* @flow */
import { connect } from 'react-redux';
import AuthForms from './authForms.jsx';

import { getAuthUser, getLanguage } from '../../reducers/';

import {
  showNotificationAndDismiss,
  enterpriseCustomerRequestThunk,
} from '../../helpers/actionCreators/redux';

export default connect(
  (state: RootReducerState) => ({
    authUser: getAuthUser(state),
    language: getLanguage(state),
  }),
  {
    enterpriseCustomerRequest: enterpriseCustomerRequestThunk,
    showNotification: showNotificationAndDismiss,
  }
)(AuthForms);
