// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';

import ResetPassword from './resetPassword';

import { loginThunk } from '../../../helpers/actionCreators/redux';

export default compose(
  connect(null, {
    login: loginThunk,
  })
)(ResetPassword);
