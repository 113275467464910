/* @flow */
/* First step in password reset step where the user submits their email address */
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import t from 'counterpart';
import type { FormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Text, Box } from '@eyeem-ui/atoms';

import T from '../general/translate.jsx';
import Link from '../general/link/';

import Input from '../formElements/input.jsx';
import Headline from './headline.jsx';
import SubmitButton from './submitButton.jsx';

import { REQUEST_PASSWORD_RESET } from '../../graphql/mutations/auth';
import { getHomeUrl } from '../../helpers/tools';

import validate, {
  VALIDATOR_REQUIRED,
  VALIDATOR_EMAIL,
} from '../../helpers/validators';

const required = validate(VALIDATOR_REQUIRED);
const email = validate(VALIDATOR_EMAIL);

type Props = {
  authUser: AuthUser,
  requestPasswordReset: Function,
} & FormProps;

const RequestPasswordResetForm = (props: Props) => {
  const [sent, setSent] = useState(false);
  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET);

  const submit = (values: { email: string }) =>
    requestPasswordReset({ variables: { email: values.email } })
      .then(requestCallback)
      .catch((err) => console.log('error:', err));

  const requestCallback = () => setSent(true);

  if (sent) {
    return (
      <div>
        <Box pb={2}>
          <Headline text="authForms.requestPasswordReset.success.headline" />
        </Box>
        <Box pb={4}>
          <Text color="grey50">
            {t('authForms.requestPasswordReset.success.subline')}
          </Text>
        </Box>
        <Box pt={2} mt={3}>
          <Link
            href={getHomeUrl({
              authUser: props.authUser,
            })}
            title={t('authForms.requestPasswordReset.success.link')}>
            <Text bold>{t('authForms.requestPasswordReset.success.link')}</Text>
          </Link>
        </Box>
      </div>
    );
  }

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <Box pb={2}>
        <Headline text="authForms.requestPasswordReset.headline" />
      </Box>
      <Box pb={4}>
        <Text color="grey50">
          {t('authForms.requestPasswordReset.subline')}
        </Text>
      </Box>
      <Field
        component={Input}
        placeholder={t('authForms.requestPasswordReset.email')}
        label={t('authForms.requestPasswordReset.email')}
        name="email"
        type="email"
        validate={[required, email]}
      />
      <T type="submit" component={SubmitButton}>
        authForms.requestPasswordReset.submit
      </T>
      <Box pt={4}>
        <Link
          href="/login"
          title={t('authForms.requestPasswordReset.backToLogin')}>
          <Text bold>{t('authForms.requestPasswordReset.backToLogin')}</Text>
        </Link>
      </Box>
    </form>
  );
};

export default reduxForm({
  form: 'requestResetPassword',
})(RequestPasswordResetForm);
