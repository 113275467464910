import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!) {
    resetPassword(password: $password) {
      id
      email
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      statusCode
    }
  }
`;
