/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import qs from 'qs';
import get from 'lodash/get';
import t from 'counterpart';
import { Box, Button, Text } from '@eyeem-ui/atoms';

import Link from '../../general/link/';

import Headline from '../headline.jsx';

import { isOneOf, getHomeUrl } from '../../../helpers/tools';

type Props = {
  connectApp: Function,
  authUser: AuthUser,
  linkedApps?: $ReadOnlyArray<string>,
  authRequestApp: Object,
};

class ConnectAppForm extends Component<Props> {
  componentDidMount() {
    if (this.alreadyAuthorized()) {
      this.connectApp();
    }
  }

  connectApp = () =>
    this.props.connectApp({
      urlParams: qs.parse(window.location.search.substr(1)),
      callback: this.callback,
    });

  onSubmit = (event: SyntheticInputEvent<HTMLElement>) => {
    event.preventDefault();
    return this.connectApp();
  };

  callback = (response: NormalizedData) => {
    if (response.url) {
      window.location = response.url;
    }
  };

  alreadyAuthorized = () =>
    this.props.authRequestApp &&
    this.props.linkedApps &&
    isOneOf(this.props.authRequestApp.id, this.props.linkedApps);

  render() {
    return (
      <form>
        <Headline
          text={
            this.alreadyAuthorized()
              ? 'authForms.connectApp.redirectHeadline'
              : 'authForms.connectApp.headline'
          }
          translationKey={{
            name: get(this.props.authRequestApp, 'name') || '',
          }}
        />

        {!this.alreadyAuthorized() && (
          <Box pt={4}>
            <Button type="submit" onClick={this.onSubmit} fullWidth>
              {t('authForms.connectApp.submit')}
            </Button>

            <Link
              className="typo_pStatic g_link typo-topMarginBoost-small"
              title={t('authForms.connectApp.link')}
              href={getHomeUrl({
                authUser: this.props.authUser,
              })}>
              <Text>{t('authForms.connectApp.link')}</Text>
            </Link>
          </Box>
        )}
      </form>
    );
  }
}

export default ConnectAppForm;
