import { connect } from 'react-redux';
import ConnectAppForm from './connectAppForm.jsx';

import { connectAppThunk } from '../../../helpers/actionCreators/redux';

import {
  getApp,
  getAuthRequestAppId,
  getNonNativeLinkedApps,
  getAuthUser,
} from '../../../reducers/';

export default connect(
  (state) => ({
    linkedApps: getNonNativeLinkedApps(state),
    authRequestApp: getApp(state, getAuthRequestAppId(state)),
    authUser: getAuthUser(state),
  }),
  { connectApp: connectAppThunk }
)(ConnectAppForm);
