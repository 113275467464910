/* @flow */
/* Centered Auth Forms view */
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import t from 'counterpart';
import { media } from '@eyeem-ui/theme';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Flex, Box } from '@eyeem-ui/atoms';
import RequestPasswordReset from './requestPasswordReset.jsx';
import ResetPassword from './resetPassword/';
import ResetEnterprisePassword from './resetEnterprisePassword/';
import ConnectAppForm from './connectAppForm/';
import AuthFormsMeta from './authFormsMeta';
import SignupForm from '../general/signupForm/';
import LoginForm from '../general/loginForm/';
import EnterpriseUserSignup from '../general/enterpriseUserSignup';

const globalStyles = () => `
  .reactRoot > div:first-of-type:not(.appBanner) {
    height: 100%;
  }

  .content {
    top: 0;
  }
`;

const StyledWrapper = styled(Box)`
  max-width: 400px;
  margin: auto;

  @media ${media.mediumUp} {
    width: 400px;
  }
`;

const StyledImg = styled(Box)`
  background-image: url('${(props) => props.url}');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: none;

  @media ${media.xlargeUp} {
    display: block;
  }
`;

const IMAGES = {
  buyerSignup: '/node-static/img/signup/buyer.jpg',
  creatorSignup: '/node-static/img/signup/seller.jpg',
  default: '/node-static/img/signup/seller.jpg',
  enterpriseBuyerSignup: '/node-static/img/signup/buyer.jpg',
};

export type AuthFormsProps = {
  _query: Object,
  authUser: AuthUser,
  form: string,
  language: string,
  reloadAfterAuth?: boolean,
  showNotification: Function,
};

function AuthForms(props: AuthFormsProps) {
  const backgroundImage = IMAGES[String(props.form)] || IMAGES.default;

  return (
    <ThemeProvider>
      <AuthFormsMeta form={props.form} language={props.language} />
      <Global styles={globalStyles} />
      <Flex
        justifyContent={['stretch', 'center']}
        alignItems={['flex-start', 'center']}
        pt={[5, 0]}>
        <StyledImg flex="1 1 auto" url={backgroundImage} />
        <Box p={[3, 0]} flex={['1 1 auto', '0 0 auto']} mx={[0, 0, 0, 4]}>
          <StyledWrapper px={[1, 0]} mx={['auto', 'auto', 'auto', 3]}>
            {props.form === 'login' && (
              <LoginForm
                headline="authForms.login.headline"
                reloadAfterAuth={props.reloadAfterAuth}
              />
            )}
            {(props.form === 'buyerSignup' ||
              props.form === 'creatorSignup') && (
              <SignupForm
                buyer={props.form === 'buyerSignup'}
                eventPosition={t(`authForms.${props.form}.eventPosition`)}
                headline={`authForms.${props.form}.headline`}
                authRef={
                  props.form === 'creatorSignup'
                    ? '/market/sell/start'
                    : undefined
                }
              />
            )}
            {props.form === 'enterpriseBuyerSignup' && (
              <EnterpriseUserSignup
                buyer
                query={props._query} //eslint-disable-line
                eventPosition={t(`authForms.${props.form}.eventPosition`)}
              />
            )}
            {props.form === 'requestPasswordReset' && (
              <RequestPasswordReset authUser={props.authUser} />
            )}
            {props.form === 'resetPassword' && props.resetPasswordToken && (
              <ResetPassword showNotification={props.showNotification} />
            )}
            {props.form === 'resetEnterprisePassword' && (
              <ResetEnterprisePassword
                showNotification={props.showNotification}
              />
            )}
            {props.form === 'connectApp' && <ConnectAppForm />}
          </StyledWrapper>
        </Box>
      </Flex>
    </ThemeProvider>
  );
}

export default AuthForms;
