/* @flow */

import { connect } from 'react-redux';
import EnterpriseUserSignup from './enterpriseUserSignup.jsx';

import {
  enterpriseCustomerRequestThunk,
  enterpriseRegisterCustomerUserThunk,
  showNotificationAndDismiss,
} from '../../../helpers/actionCreators/redux';

export default connect(null, {
  enterpriseCustomerRequest: enterpriseCustomerRequestThunk,
  enterpriseCustomerRegister: enterpriseRegisterCustomerUserThunk,
  showNotification: showNotificationAndDismiss,
})(EnterpriseUserSignup);
