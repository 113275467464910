// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';

import ResetEnterprisePassword from './resetEnterprisePassword';

import { getSessionField } from '../../../reducers/';
import { SESSION_FIELD_PASSWORD_RESET_TOKEN } from '../../../constants/misc';
import {
  setSessionFieldThunk,
  resetEnterprisePasswordThunk,
} from '../../../helpers/actionCreators/redux';

const mapStateToProps = (state: RootReducerState) => ({
  passwordResetToken:
    getSessionField(state, SESSION_FIELD_PASSWORD_RESET_TOKEN) || null,
});

export default compose(
  connect(mapStateToProps, {
    setSessionField: setSessionFieldThunk,
    resetEnterprisePassword: resetEnterprisePasswordThunk,
  })
)(ResetEnterprisePassword);
