/* @flow */
/* Reset Password Form */
import { useMutation } from '@apollo/client';
import t from 'counterpart';
import type { FormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Box } from '@eyeem-ui/atoms';

import T from '../../general/translate.jsx';
import SubmitButton from '../submitButton';
import Headline from '../headline.jsx';

import InputPassword from '../../formElements/inputPassword';

import withRouter from '../../../helpers/hoc/withRouter.jsx';
import { getHomeUrl } from '../../../helpers/tools';

import validate, {
  VALIDATOR_REQUIRED,
  VALIDATOR_PASSWORD,
} from '../../../helpers/validators';
import { RESET_PASSWORD } from '../../../graphql/mutations/auth';

const required = validate(VALIDATOR_REQUIRED);
const password = validate(VALIDATOR_PASSWORD);

type Props = {
  login: Function,
  showNotification: Function,
} & FormProps &
  WithRouterProps;

const ResetPasswordForm = (props: Props) => {
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const submit = (values: { password: string }) =>
    resetPassword({
      variables: { password: values.password },
    })
      .then((res) =>
        props.login({
          password: values.password,
          email: res.data.resetPassword.email,
        })
      )
      .then(requestCallback);

  const requestCallback = (res: AuthResponse) => {
    props.showNotification({
      notification: 'passwordReset',
    });
    props.navigate(
      getHomeUrl({
        authUser: res.authUser,
      })
    );
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <Box mb={3}>
        <Headline text="authForms.resetPassword.headline" />
      </Box>
      <Field
        component={InputPassword}
        placeholder={t('authForms.resetPassword.password')}
        label={t('authForms.resetPassword.password')}
        name="password"
        validate={[required, password]}
      />
      <T component={SubmitButton} type="submit">
        authForms.resetPassword.submit
      </T>
    </form>
  );
};

export default reduxForm({
  form: 'resetPassword',
})(withRouter(ResetPasswordForm));
