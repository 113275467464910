/* @flow */
/* Reset Password Form */
import { Component } from 'react';
import get from 'lodash/get';

import t from 'counterpart';
import type { FormProps } from 'redux-form';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Box } from '@eyeem-ui/atoms';
import T from '../../general/translate.jsx';
import InputPassword from '../../formElements/inputPassword.jsx';
import SubmitButton from '../submitButton';
import Headline from '../headline.jsx';

import withRouter from '../../../helpers/hoc/withRouter.jsx';
import { SESSION_FIELD_PASSWORD_RESET_TOKEN } from '../../../constants/misc';

import validate, {
  VALIDATOR_REQUIRED,
  VALIDATOR_ENTERPRISE_PASSWORD,
  PASSWORDS_MUST_MATCH,
} from '../../../helpers/validators';

const required = validate(VALIDATOR_REQUIRED);
const password = validate(VALIDATOR_ENTERPRISE_PASSWORD);
const passwordsMustMatch = validate(PASSWORDS_MUST_MATCH);

type Props = {
  resetEnterprisePassword: Function,
  enterpriseCustomerRequest: Function,
  showNotification: Function,
  query: Object,
  passwordResetToken: string,
} & FormProps &
  WithRouterProps;

class ResetEnterprisePassword extends Component<Props> {
  submit = (values: { password: string }) =>
    this.props
      .resetEnterprisePassword({
        password: values.password,
        token: this.props.passwordResetToken,
      })
      .then(() => this.requestCallback())
      .catch((res) => {
        const errorFieldsWithMessages = get(
          res,
          'body.messagesByField',
          undefined
        );

        if (res.statusCode >= 400 && !!errorFieldsWithMessages) {
          throw new SubmissionError(errorFieldsWithMessages);
        }
      });

  requestCallback = () =>
    this.props.setSessionField(
      {
        name: SESSION_FIELD_PASSWORD_RESET_TOKEN,
        value: null,
      },
      () => this.props.navigate('/')
    );

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <Box mb={3}>
          <Headline text="authForms.resetPassword.headline" />
        </Box>
        <Field
          component={InputPassword}
          placeholder={t('authForms.resetPassword.password')}
          label={t('authForms.resetPassword.password')}
          name="password"
          type="password"
          validate={[required, password]}
        />
        <Field
          component={InputPassword}
          placeholder={t('authForms.resetPassword.confirmPassword')}
          label={t('authForms.resetPassword.confirmPassword')}
          name="confirmPassword"
          type="password"
          validate={[required, password, passwordsMustMatch]}
        />
        <T component={SubmitButton} type="submit">
          authForms.resetPassword.submit
        </T>
      </form>
    );
  }
}

export default reduxForm({
  form: 'resetEnterprisePassword',
})(withRouter(ResetEnterprisePassword));
